.testimonial{
    background-color: hsla(230, 18%, 47%, 0.482);
    width: 100%;
    padding: 30px 25px;
    border-radius: 5px;
    flex: 1;
    min-width: 200px;
    transition: 0.3s;
    &:hover{
        transition: 0.3s;
        transform: scale(1.06);
        cursor: default;
    }
    p{
        color: whitesmoke;
    }
    .person{
        display: flex;
        gap: 10px;
        align-items: center;
        padding-top: 10px;
        img{
            width: 100%;
            border-radius: 10%;
            border : double 5px rgb(255, 255, 255);
        }
        .person-info{
            display: flex;
            flex-direction: column;
            .name{
                font-weight: bold;
                font-size: 12px;
            }
            .company-role{
                font-size: 10px;
                color: whitesmoke;
                letter-spacing: 1px;
            }
        }
    }
}