.intro {
  padding-top: 200px;
  height: 800px;
  background-image: url("../../../../../public/images/back1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display:flex;
  align-items: center;
  position: relative;
    .explain {
      position: absolute;
      right: 0;
      top: 30%;
      display: flex;
      flex-direction: column;
      width: 50%;
      height: 50%;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      h1 {
        text-align: center;
        color: hsl(238, 46%, 13%);
      }
      a {
        text-align: center;
        text-decoration-line: none;
        padding: 12px 30px;
        margin-top: 10px;
        background-color: var(--cyan);
        background: var(--cyan);
        background: linear-gradient(90deg, hsl(17, 61%, 38%) 0%, hsl(17, 100%, 48%) 100%);
        color: #fff;
        border: double 5px;
        border-radius: 100px;
        font-weight: bold;
        cursor: pointer;
        transition: 1s;
        &:hover{
          transition: 1s;
          background: linear-gradient(90deg, hsl(17, 100%, 31%) 100%, hsl(355, 100%, 50%) 100%);
          transform: scale(1.1);
        }
        div {
          font-size: smaller;
            color: rgb(8, 7, 41);
        }
      }
      
    }
}
@media (max-width: 768px) {
  .intro {
    padding-top: 200px;
    background-image: url("../../../../../public/images/back1.jpg");
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
      .explain {
        position: absolute;
        display: flex;
        top: 50%;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        justify-content: end;
        h1 {
          text-align: center;
          color: white;
        }
        a {
          padding: 12px 30px;
          margin-top: 10px;
          margin-bottom: 30px;
          background-color: var(--cyan);
          background: var(--cyan);
          background: linear-gradient(90deg, hsl(17, 61%, 38%) 0%, hsl(17, 100%, 48%) 100%);
          color: #fff;
          border: double 5px;
          border-radius: 100px;
          font-weight: bold;
          cursor: pointer;
          transition: 1s;
          &:hover{
            transition: 1s;
            background: linear-gradient(90deg, hsl(17, 100%, 31%) 100%, hsl(355, 100%, 50%) 100%);
            transform: scale(1.1);
          }
          div {
            font-size: smaller;
              color: rgb(8, 7, 41);
          }
        }
      }
      
    }
}
