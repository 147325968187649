.footer{
    background-color: hsl(216, 53%, 9%);
    padding: 20px 10% 20px 10%;
    .logo{
        width: 100px;
        display: inline;
    }
    .info{
        display: flex;
        justify-content: space-around;
        margin-top: 30px;
        flex-wrap: wrap;
     .locationText{
        svg{
            padding: 10px;
            
        }
        p{
            margin-top: 5px;
        }
        display: flex;
        width: 450px;
     }   
     .phoneEmail{
        display: flex;
        flex-direction: column;
        span{
            display: flex;
            align-items: center;
            cursor: pointer;
            svg{
                padding: 10px;
            }
        }
     }
     .footerNav{
        ul{
            display: grid;
            grid-template-columns: 1fr 1fr;
            list-style-type: none;
            gap: 15px;
            li{
                color: lightgray;
                &:hover{
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
     }
     .socialMedia{
        display: flex;
        padding: 10px;
        svg{
            font-size: 20px;
            padding: 5px;
        }
        button{
            color: white;
            background-color: hsl(216, 53%, 9%);
            margin-left: 10px;
            border: solid 2px white;
            border-radius: 10px;
        }
     }
    }
    
}