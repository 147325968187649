.header {
  padding: 10px 8%;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background-color: hsl(217, 28%, 15%);
  z-index: 2;
  .logo{
    width: 100px;
  }
  span{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 40px;
    align-content: center;
  }

  .menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .hamburger {
      display: none; /* Hide the hamburger button by default */
      background: none;
      border: none;
      color: lightgray;
      font-size: 24px;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        color: #fff;
      }
    }

    .menu-list {
      display: flex;
      gap: 50px;
      list-style-type: none;
      margin: 0;

      li {
        cursor: pointer;
        color: lightgray;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .header {
    padding: 10px 5%;
    .logo{
      width: 70px;
    }
    span{
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 30px;
    }
    .menu {
      .hamburger {
        display: block; /* Show the hamburger button */
      }
      
    
      .menu-list {
        display: none; /* Hide the menu list by default on mobile */
        flex-direction: column; /* Stack menu items vertically */
        position: absolute;
        top: 100%;
        right: 0;
        background-color: hsl(217, 28%, 15%);
        width: 100%;
        padding: 10px 0;

        li {
          padding: 10px 20px; /* Add padding for touch targets */
          text-align: center; /* Center the text */
        }
      }

      &.active .menu-list {
        display: flex; /* Show the menu list when active */
      }
    }
  }
}