.introcraft {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url("../../../../../public/images/back3.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .wrappercraft {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 50%;
    margin: 0 auto;
    margin-top: 30px;
    background-color: rgba(0, 0, 0, 0.499);
    border-radius: 30px;
    padding: 20px;
    align-items: center;
    img {
      width: 10%;
    }
    .explaincraft {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 0 auto;
      align-items: center;
      h1 {
        text-align: left;
        margin-left: 30px;
      }
    }
    
  }
}
@media (max-width: 768px) {
  .introcraft {
    padding-top: 107px;
    background-image: url("../../../../../public/images/back3.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  
    .wrappercraft {
      box-sizing: border-box;
      display: block;
      gap: 20px;
      flex-wrap: wrap;
      width: 90%;
      margin: 0 auto;
      margin-top: 30px;
      align-items: center;
      img {
        width: 100%;
      }
      .explaincraft {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        align-items: center;
        h1 {
          text-align: left;
        }
      }
      
    }
  }
}
