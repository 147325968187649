.testimonials{
    background-image: url("../../../../../public/images/back2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    padding: 50px 10%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
}