@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

:root {
  /* Primary */
  /* 
- Dark Blue (intro and email sign up background): hsl(217, 28%, 15%)
- Dark Blue (main background): hsl(218, 28%, 13%)
- Dark Blue (footer background): hsl(216, 53%, 9%)
- Dark Blue (testimonials background): hsl(219, 30%, 18%)
*/
  /* Accent */
  /* inside call-to-action gradient */
  --cyan: hsl(176, 68%, 64%);
  /* inside call-to-action gradient */
  --blue: hsl(198, 60%, 50%);
  /* Error */
  --light-red: hsl(0, 100%, 63%);

}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: hsl(218, 28%, 13%);
  color: #fff;
  overflow-x: hidden;
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Raleway', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}